// About.js
import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Layout from "../components/layout";
import {StaticImage} from "gatsby-plugin-image";

const Shop = () => {
    return (
        <Layout>
            <h2>Yoyogi Dojo '74</h2>
            <Row>
                <Col xs={3}>
                    <StaticImage src="../images/yoyogiDojo.jpg" alt="Yoyogi Dojo"/>
                </Col>
                <Col>

                    <h3>Yoyogi Dojo '74 - A young man's quest for self enlightenment through rigorous training</h3>
                    <p>"Sensei Graham Ravey holds the distinction of being the foreigner who trained with Morio Higaonna
                        the longest in Japan. This is the story of his journey in the martial arts. The book is filled
                        with stories of the famous Yoyogi dojo in Tokyo. Ravey gives accounts of the many students and
                        instructors from around the world who visited Higaonna's school. Among others, from John Jarvis
                        of Kyokushin, Benny the Jet Urquidez and Eddie "Monster Man" Everett to Terry O'Neil and Harry
                        Cook...</p>

                    <p>There are plenty of photos and the chapter titled "The Legend of Yoyogi Dojo" offers a rare
                        insight to Ken Ogawa, one of the most accomplished Black Belt instructors to come out of
                        Higaonna's school.</p>

                    <p>A worthwhile read for anyone who wants to know what it was like to train in Japan with one of the
                        best Goju karate teachers." - A. Bustillo</p>

                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post" class="shop">
                        <input type="hidden" name="cmd" value="_s-xclick"/>
                        <input type="hidden" name="hosted_button_id" value="JCZMURQR5MR6J"/>
                        <p><strong>Please select Postage Option before &quot;Add to Cart&quot;</strong></p>
                        <select name="os0">
                            <option value="Select Postage Option">Select Postage Option $0.00 AUD</option>
                            <option value="Book + Postage within Australia">Book + Postage within Australia $22.00 AUD
                            </option>
                            <option value="Book + Postage International">Book + Postage International $33.00 AUD
                            </option>
                        </select>
                        <input type="hidden" name="currency_code" value="AUD"/>
                        <p><input type="image" src="https://www.paypalobjects.com/en_AU/i/btn/btn_cart_LG.gif"
                                  border="0" name="submit" alt="PayPal -- The safer, easier way to pay online."/></p>
                    </form>
                </Col>
            </Row>
            <Row>
            </Row>
        </Layout>);
}

export default Shop;
